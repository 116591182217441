import cx from 'classnames'
import { Button } from 'components'
import { MdClose } from 'react-icons/md'
import style from './Chip.module.css'

const Chip = ({ className = {}, text, onDelete, ...props }) => {
  if (!text) return null

  return (
    <div className={cx(style.chip, className.chip)} {...props}>
      <div className={cx(style['chip__text'], className.text)}>{text}</div>
      <Button
        className={{ button: cx(style['chip__delete'], className.button) }}
        icon={<MdClose />}
        onClick={onDelete}
      />
    </div>
  )
}

export default Chip
