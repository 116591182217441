import React, { useReducer } from 'react'
import { createRoot } from 'react-dom/client'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import messages from 'messages/zh-TW'
import MessageContext from 'contexts/MessageContext'
import StateContext from 'contexts/StateContext'
import App from 'App'
import * as session from 'reducers/session'
import * as local from 'reducers/local'
import reportWebVitals from './reportWebVitals'
import './index.css'

const Root = () => {
  const [sessionState, sessionDispatch] = useReducer(
    session.reducer,
    session.initialState,
  )
  const [localState, localDispatch] = useReducer(
    local.reducer,
    local.initialState,
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <MessageContext.Provider value={{ messages }}>
        <StateContext.Provider
          value={{
            session: { state: sessionState, dispatch: sessionDispatch },
            local: { state: localState, dispatch: localDispatch },
          }}
        >
          <App />
        </StateContext.Provider>
      </MessageContext.Provider>
    </DndProvider>
  )
}

createRoot(document.getElementById('root')).render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
