const staff = {
  'module.staff': '帳號',
  'staff.notFound': '帳號不存在',
  'staff.title.list': '帳號目錄',
  'staff.title.add': '新增帳號',
  'staff.title.edit': '修改帳號',
  'staff.title.view': '帳號詳情',
  'staff.field.username': '帳號(Email)',
  'staff.field.name': '姓名',
  'staff.field.password': '密碼',
}

export default staff
