import React from 'react'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'actions/formAction'
import { request } from 'utilities/request'
import { TextInput } from 'components'
import { TOKEN_SET, USER_SET, TOKEN_REMOVE } from 'constants/actionType'

export const initialState = () =>
  initializeState({
    username: '',
    password: '',
  })

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const handleChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    username: (
      <TextInput
        id="username"
        autoFocus
        placeholder="login.field.username"
        value={state.username}
        autoComplete="username"
        onChange={handleChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        id="password"
        type="password"
        placeholder="login.field.password"
        value={state.password}
        autoComplete="current-password"
        onChange={handleChange('password')}
        errMsg={state.__error__.password}
      />
    ),
  }
}

export const handlers = ({ session, local, state, setState, navigate }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = await createToken({ session, local, state })
    if (!ok) return

    const { accessToken, refreshToken, claim } = data
    local.dispatch({ type: USER_SET, user: claim })
    local.dispatch({ type: TOKEN_SET, token: { accessToken, refreshToken } })
    navigate('/')
  },
})

async function createToken({ session, local, state }) {
  const { username, password } = state
  return request({
    session,
    local,
    url: process.env.REACT_APP_TOKEN_URL,
    body: {
      action: 'createStaff',
      data: { hostname: window.location.hostname, username, password },
    },
  })
}

export async function handleLogout({ local, session, navigate }) {
  const { refreshToken } = local.state.token
  const [ok] = await request({
    session,
    local,
    url: process.env.REACT_APP_TOKEN_URL,
    body: { action: 'del', data: { refreshToken } },
  })
  if (!ok) return

  local.dispatch({ type: TOKEN_REMOVE })
  navigate('/login')
}
