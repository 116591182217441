export const COUNT_PER_PAGE = 25
const ADJACENTS = 2

export function getInitPagination(session, totalCount) {
  const { pageId, paginationMap } = session.state
  const pagination = paginationMap[pageId] || []
  if (pagination) return pagination
  return getPagination({ totalCount })
}

export const getPagination = ({
  page = 1,
  countPerPage = COUNT_PER_PAGE,
  totalCount = 0,
}) => {
  let totalPage = Math.ceil(totalCount / countPerPage)

  if (totalPage === 0) totalPage = 1
  if (page <= 0) page = 1
  // if (page > totalPage) page = totalPage

  let prevPage = page === 1 ? 1 : page - 1
  let nextPage = page === totalPage ? totalPage : page + 1

  let startPage = page > ADJACENTS ? page - ADJACENTS : 1
  let endPage = startPage + ADJACENTS * 2

  if (endPage > totalPage) endPage = totalPage

  const pages = []
  for (let i = 0; i < endPage - startPage + 1; i++) {
    pages[i] = startPage + i
  }

  return {
    totalCount,
    totalPage,
    page,
    pages,
    prevPage,
    nextPage,
    countPerPage,
  }
}

export const getLimit = ({ page = 1, countPerPage = COUNT_PER_PAGE }) => {
  const offset = (page - 1) * countPerPage
  return { offset, count: countPerPage }
}
