import { useEffect, useState } from 'react'
import { Collapsible, Link, Message } from 'components'
import { FaClipboardList, FaGlobe, FaWarehouse } from 'react-icons/fa'
import { IoMdArrowDropdown } from 'react-icons/io'
import { GoPackage } from 'react-icons/go'
import { TbShoppingCartDiscount } from 'react-icons/tb'
import style from './Sidebar.module.css'
import Logo from 'assets/svg/Logo'
import { MdDriveFolderUpload } from 'react-icons/md'

const Sidebar = ({ open, onToggle, pageId }) => {
  const handleClick =
    (href) =>
    ({ navigate }) => {
      navigate(href)
      if (window.innerWidth < 576) onToggle()
    }

  const renderLink = ({ idList, label, icon, url }) => (
    <NavLink
      key={url}
      icon={icon}
      label={label}
      onClick={handleClick(url)}
      active={idList.includes(pageId)}
    />
  )

  const renderGroup = ({ label, icon, links }) => {
    const idList = links.reduce((result, item) => {
      result = result.concat(item.idList)
      return result
    }, [])

    return (
      <NavGroup icon={icon} label={label} active={idList.includes(pageId)}>
        {links.map(renderLink)}
      </NavGroup>
    )
  }

  return (
    <div className={style['sidebar__wrap']} style={{ width: open ? 250 : 0 }}>
      <div className={style['sidebar']}>
        <Brand />
        {renderLink({
          label: 'order.title.list',
          icon: <FaClipboardList />,
          url: 'order',
          idList: ['order-list'],
        })}
        {renderLink({
          label: 'product.title.list',
          icon: <GoPackage />,
          url: 'product',
          idList: ['product-list', 'product-page'],
        })}
        {renderGroup({
          label: 'sidebar.stock',
          icon: <FaWarehouse />,
          links: [
            {
              label: 'inventory.title.list',
              url: 'inventory',
              idList: ['inventory-list'],
            },
            {
              label: 'buy.title.list',
              url: 'buy',
              idList: ['buy-list', 'buy-page'],
            },
            {
              label: 'refund.title.list',
              url: 'refund',
              idList: ['refund-list', 'refund-page'],
            },
            {
              label: 'adjust.title.list',
              url: 'adjust',
              idList: ['adjust-list', 'adjust-page'],
            },
          ],
        })}
        {renderGroup({
          label: 'sidebar.website',
          icon: <FaGlobe />,
          links: [
            {
              label: 'app.title.edit',
              url: 'app/edit',
              idList: ['app-page'],
            },
            {
              label: 'categoryShelf.title.list',
              url: 'category/shelf',
              idList: [
                'categoryShelf-list',
                'categoryShelf-page',
                'categoryProduct-list',
                'categoryProduct-page',
              ],
            },
            {
              label: 'shelf.title.list',
              url: 'shelf',
              idList: ['shelf-list', 'shelf-page'],
            },
            {
              label: 'promo.title.list',
              url: 'promo',
              idList: ['promo-list'],
            },
            {
              label: 'popular.title.list',
              url: 'popular',
              idList: ['popular-list'],
            },
            {
              label: 'topic.title.list',
              url: 'topic',
              idList: ['topic-list'],
            },
            {
              label: 'page.title.list',
              url: 'page',
              idList: ['page-list', 'page-page'],
            },
            {
              label: 'link.title.list',
              url: 'link',
              idList: ['link-list'],
            },
          ],
        })}
        {renderLink({
          label: 'discount.title.list',
          icon: <TbShoppingCartDiscount />,
          url: 'discount',
          idList: ['discount-list', 'discount-page'],
        })}
        {renderGroup({
          label: 'sidebar.report',
          icon: <MdDriveFolderUpload />,
          links: [
            {
              label: 'reportOrder.title',
              url: 'report/order',
              idList: ['reportOrder-page'],
            },
          ],
        })}
        {/* <NavGroup
          icon={<MdSettings />}
          label="sidebar.setting"
          active={['warehouse-list'].includes(pageId)}
        >
          <NavLink
            label="warehouse.title.list"
            onClick={handleClick('warehouse')}
            active={['warehouse-list'].includes(pageId)}
          />
          <NavLink
            label="staff.title.list"
            onClick={handleClick('staff')}
            active={['staff-list'].includes(pageId)}
          />
        </NavGroup> */}
      </div>
    </div>
  )
}

const Brand = () => (
  <div className={style['sidebar__logo']}>
    <div className={style['sidebar__logo__icon']}>
      <Logo color="#fff" />
    </div>
    <Message className={style['sidebar__logo__text']} id="app.name" />
  </div>
)

const NavGroup = ({ active, icon, label, children }) => {
  const [open, setOpen] = useState(active)

  useEffect(() => {
    setOpen(active)
  }, [active])

  return (
    <>
      <Link
        className={{
          link: style['sidebar__group'],
          'link--active': active && style['sidebar__group--active'],
          'link__icon--left': style['sidebar__group__icon--left'],
        }}
        leftIcon={icon}
        rightIcon={<IoMdArrowDropdown />}
        label={label}
        onClick={() => setOpen(!open)}
      />
      <Collapsible className={style['sidebar__group__content']} open={open}>
        {children}
      </Collapsible>
    </>
  )
}

const NavLink = ({ icon, label, onClick, active }) => (
  <Link
    className={{
      link: style['sidebar__link'],
      'link--active': active && style['sidebar__link--active'],
      'link__icon--left': style['sidebar__link__icon--left'],
    }}
    leftIcon={icon}
    label={label}
    onClick={onClick}
  />
)

export default Sidebar
