const category = {
  'module.categoryShelf': '商品類別',
  'module.categoryProduct': '商品',
  // 'category.title.list': '商品類別',
  // 'category.title.add': '新增商品類別',
  // 'category.title.edit': '修改商品類別',
  // 'category.field.name': '名稱',
  'categoryShelf.title.list': '商品類別',
  'categoryShelf.title.add': '新增商品類別',
  'categoryShelf.title.edit': '修改商品類別',
  'categoryProduct.title.list': '商品目錄',
  'categoryProduct.title.add': '新增商品',
  'categoryProduct.title.edit': '修改商品',
}

export default category
