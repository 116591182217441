const app = {
  'module.app': '商家資料',
  'app.title.edit': '一般設定',
  'app.field.brand': '網站名稱',
  'app.field.name': '公司名稱',
  'app.field.email': '公司Email',
  'app.field.phone': '公司電話',
  'app.field.address': '公司地址',
  'app.field.headerLogo': 'Header Logo',
  'app.field.footerLogo': 'Footer Logo',
  'app.field.color': '網站背景顏色',
}

export default app
