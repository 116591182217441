import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import useGlobalState from 'hooks/useGlobalState'
import useMessage from 'hooks/useMessage'
import { initialState, fields, handlers } from './loginAction'
import style from './Login.module.css'
import { Button, Message } from 'components'

const Login = () => {
  const isMounted = useRef(1)
  const { session, local } = useGlobalState()
  const message = useMessage()
  const navigate = useNavigate()
  const [state, setStateVal] = useState(initialState())
  const setState = (value) => {
    if (isMounted.current) setStateVal(value)
  }
  const form = fields({ session, local, state, setState })
  const handler = handlers({ session, local, state, setState, navigate })

  useEffect(() => {
    isMounted.current = 1
    document.title = message({ id: 'login.title' })
    return () => {
      isMounted.current = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderHeader = () => (
    <div className={style['login__header']}>
      <Message id="app.name" />
    </div>
  )

  const renderFooter = () => (
    <div className={style['login__footer']}>
      <div className={style['login__footer__content']}>
        &copy; 2022 藍侃科技有限公司. All rights reserved.
      </div>
    </div>
  )

  return (
    <div className={style['login__container']}>
      <form
        className={style['login']}
        onSubmit={useCallbackOnce(handler.handleSubmit)}
      >
        {renderHeader()}
        <div className={style['login__form']}>
          {form.username}
          {form.password}
        </div>
        <div className={style['login__action']}>
          <Button label="btn.save" type="submit" />
        </div>
      </form>
      {renderFooter()}
    </div>
  )
}

export default Login
