import cx from 'classnames'
import { Link } from 'components'
import { MdArrowBack } from 'react-icons/md'
import style from './Toolbar.module.css'

const Toolbar = ({ className, show = true, backUrl, children }) => {
  if (!show) return null
  return (
    <div className={cx(style.toolbar, className)}>
      <Link
        show={!!backUrl}
        icon={<MdArrowBack />}
        label="btn.back"
        href={backUrl}
      />
      <div className={style['toolbar__content']}>{children}</div>
    </div>
  )
}

export default Toolbar
