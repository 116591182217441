const promo = {
  'module.promo': '首頁主打',
  'promo.title.list': '首頁主打',
  'promo.title.add': '新增首頁主打',
  'promo.title.edit': '修改首頁主打',
  'promo.field.linkType': '連結類型',
  'promo.field.linkValue': '連結位置',
  'promo.type.PRODUCT': '商品頁面',
  'promo.type.CATEGORY': '商品分類',
}

export default promo
