const buy = {
  'module.buy': '進倉單',
  'buy.toLocationId.required': '倉別為必填欄位',
  'buy.title.list': '進倉單',
  'buy.title.add': '新增進倉單',
  'buy.title.edit': '修改進倉單',
  'buy.title.view': '進倉單詳情',
  'buy.field.invoicedQuantity': '下單數量',
  'buy.field.dispatchedQuantity': '出貨數量',
  'buy.field.quantity': '到貨數量',
}

export default buy
