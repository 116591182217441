import { useState } from 'react'

export function useCallbackOnce(callback, delay = 600) {
  const [lastClicked, setLastClicked] = useState(0)

  return async (event) => {
    event.preventDefault()

    const curTime = new Date().getTime()
    if (curTime - lastClicked < delay) return

    try {
      await callback(event)
    } finally {
      setLastClicked(curTime)
    }
  }
}
