import { Button } from 'components'
import { MdLogout, MdMenu } from 'react-icons/md'
import { handleLogout } from 'views/Login/loginAction'
import style from './Navbar.module.css'

const Navbar = ({ local, session, navigate, onToggle }) => {
  return (
    <div className={style.navbar}>
      <Button
        className={{
          button: style['navbar__button'],
          icon: style['navbar__button__icon'],
        }}
        leftIcon={<MdMenu />}
        onClick={onToggle}
      />
      <Button
        className={{
          button: style['navbar__button'],
          icon: style['navbar__button__icon'],
        }}
        leftIcon={<MdLogout />}
        onClick={() => handleLogout({ local, session, navigate })}
      />
    </div>
  )
}

export default Navbar
