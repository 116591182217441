import { COUNT_PER_PAGE } from './paginationAction'
import { Button } from 'components'
import { MdDelete, MdEdit } from 'react-icons/md'

export function getColumns({
  session,
  navigate,
  columns,
  profile,
  showSeq,
  showEditButton,
  showDeleteButton,
  editValues,
  pagination,
  onEditClick,
  onEditSubmit,
  onDeleteClick,
}) {
  const cols = columns
    .filter(({ profile: colProfile = ['add', 'edit', 'view'] }) =>
      colProfile.includes(profile),
    )
    .filter(({ format = ['html'] }) => format.includes('html'))
    .filter(({ show = true }) => show)

  if (showSeq) {
    cols.unshift({
      id: '__SEQ__',
      label: 'field.seq',
      width: '1px',
      render: ({ index }) => {
        const { page = 1, countPerPage = COUNT_PER_PAGE } = pagination || {}
        const curPage = page || 1
        return (curPage - 1) * countPerPage + index + 1
      },
    })
  }

  if (showEditButton || showDeleteButton) {
    const renderEditButton = (item) => {
      if (!showEditButton) return null

      let handleEditClick = () => {}
      if (onEditClick) {
        handleEditClick = () => onEditClick({ navigate, value: item })
      }
      if (onEditSubmit) {
        handleEditClick = () => {
          cols.forEach((col) => {
            const { id, getValue } = col
            editValues[id] = !!getValue ? getValue(item.row) : item.row[id]
          })
          onEditSubmit(editValues, item.index)
        }
      }
      return (
        <Button
          style={{ marginRight: 'var(--space-1)' }}
          icon={<MdEdit />}
          onClick={handleEditClick}
        />
      )
    }

    const renderDeleteButton = (item) => {
      if (!showDeleteButton || !onDeleteClick) return null
      return (
        <Button
          icon={<MdDelete />}
          onClick={() => {
            return onDeleteClick({ session, value: item })
          }}
        />
      )
    }

    cols.push({
      id: '__ACTION__',
      align: 'right',
      width: '1px',
      noWrap: true,
      renderHtml: (item) => (
        <>
          {renderEditButton(item)}
          {renderDeleteButton(item)}
        </>
      ),
    })
  }

  return cols
}
