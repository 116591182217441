import { Button, Message } from 'components'
import {
  MdMoreHoriz,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md'
import style from './Pagination.module.css'

const Pagination = ({ value, onClick }) => {
  const { pages, page, prevPage, nextPage, totalPage, totalCount } = value
  if (totalPage === 0) return null

  const renderCounter = () => (
    <Message
      id={{ id: 'table.pagination.totalCount', values: { totalCount } }}
    />
  )

  const renderFirst = () => {
    if (pages[0] === 1) return null
    return (
      <Button
        className={{ button: style['pagination__page'] }}
        key="page-first"
        onClick={() => onClick(1)}
      >
        {1}
      </Button>
    )
  }

  const renderFirstEllipsis = () => {
    if (pages[0] <= 2) return null
    return <MdMoreHoriz color="#999" />
  }

  const renderLast = () => {
    if (pages[pages.length - 1] === totalPage) return null
    return (
      <Button
        className={{ button: style['pagination__page'] }}
        key="page-last"
        onClick={() => onClick(totalPage)}
      >
        {totalPage}
      </Button>
    )
  }

  const renderLastEllipsis = () => {
    if (pages[pages.length - 1] >= totalPage - 1) return null
    return <MdMoreHoriz color="#999" />
  }

  const renderPages = () =>
    pages.map((item) => {
      const isActive = page === item
      return (
        <Button
          className={{ button: style['pagination__page'] }}
          style={{
            color: isActive
              ? 'var(--color-secondary-1)'
              : 'var(--color-dark-1)',
          }}
          key={`page-${item}`}
          onClick={() => onClick(item)}
        >
          {item}
        </Button>
      )
    })

  const renderPrev = () => {
    const startPage = pages[0]
    return (
      <Button
        className={{ button: style['pagination__page'] }}
        disabled={page === startPage}
        icon={<MdKeyboardArrowLeft size="28px" />}
        onClick={() => onClick(prevPage)}
      />
    )
  }

  const renderNext = () => {
    const endPage = pages[pages.length - 1]
    return (
      <Button
        className={{ button: style['pagination__page'] }}
        disabled={page === endPage}
        icon={<MdKeyboardArrowRight size="28px" />}
        onClick={() => onClick(nextPage)}
      />
    )
  }

  return (
    <div className={style.pagination}>
      {renderCounter()}
      <div className={style['pagination__page-panel']}>
        {renderPrev()}
        {renderFirst()}
        {renderFirstEllipsis()}
        {renderPages()}
        {renderLastEllipsis()}
        {renderLast()}
        {renderNext()}
      </div>
    </div>
  )
}

export default Pagination
