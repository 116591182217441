import * as type from 'constants/actionType'

export const initialState = {
  alerts: [],
  loading: false,
  pageId: '',
  confirmModal: initConfirmModal(),
  filterMap: {},
  paginationMap: {},
  editorImagesToAdd: [],
  editorImagesToDel: [],
  editorImageFilepath: '',
}

export function reducer(state, action) {
  switch (action.type) {
    case type.ALERT_ADD:
      return { ...state, alerts: addAlert(state.alerts, action.item) }
    case type.ALERT_REMOVE:
      return { ...state, alerts: removeAlert(state.alerts, action.idx) }
    case type.LOADING_SHOW:
      return { ...state, loading: true }
    case type.LOADING_HIDE:
      return { ...state, loading: false }
    case type.PAGE_SET:
      return { ...state, pageId: action.id }
    case type.CONFIRM_OPEN:
      return { ...state, confirmModal: action.item }
    case type.CONFIRM_CLOSE:
      return { ...state, confirmModal: initConfirmModal() }
    case type.FILTER_SET:
      return {
        ...state,
        filterMap: setMap(state.filterMap, state.pageId, action.filterValues),
      }
    case type.PAGINATION_SET:
      return {
        ...state,
        paginationMap: setMap(
          state.paginationMap,
          state.pageId,
          action.pagination,
        ),
      }
    case type.EDITOR_IMAGE_ADD:
      return {
        ...state,
        editorImagesToAdd: addToList(state.editorImagesToAdd, action.value),
      }
    case type.EDITOR_IMAGE_DEL:
      return {
        ...state,
        ...removeImage(state, action.value),
      }
    case type.EDITOR_IMAGE_RESET:
      return { ...state, editorImagesToAdd: [], editorImagesToDel: [] }
    case type.EDITOR_FILEPATH_SET:
      return { ...state, editorImageFilepath: action.value }
    default:
      return state
  }
}

function initConfirmModal() {
  return { open: false, title: '', label: '', onSubmit: () => {} }
}

function addAlert(list, data) {
  const exist = list.some((item) => item.message === data.message)
  if (exist) return list
  return [...list, data]
}

function removeAlert(list, idx) {
  const result = [...list]
  result.splice(idx, 1)
  return result
}

function setMap(map, key, value) {
  const result = { ...map }
  result[key] = value
  return result
}

function addToList(list, item, key = 'id') {
  const idx = list.findIndex((i) => i[key] === item[key])
  if (idx === -1) {
    list.push(item)
  } else {
    list.splice(idx, 1, item)
  }
  return list
}

function removeImage(state, id) {
  const { editorImagesToAdd, editorImagesToDel } = state
  const toAddIdx = editorImagesToAdd.findIndex((item) => item === id)

  if (toAddIdx >= 0) {
    const newToAdd = [...editorImagesToAdd]
    newToAdd.splice(toAddIdx, 1)
    return {
      editorImagesToAdd: newToAdd,
      editorImagesToDel,
    }
  }

  editorImagesToDel.push(id)
  return { editorImagesToAdd, editorImagesToDel }
}
