import { useRef, useState, useEffect } from 'react'
import useMessage from 'hooks/useMessage'
import {
  getFilterLabel,
  getInitFilterInputs,
  getInitFilterSelect,
  handleSubmit,
} from './filterAction'
import { Select } from 'components'
import FilterDrawer from './FilterDrawer'
import style from './Filter.module.css'
import Button from 'components/Button/Button'
import { MdMore, MdSearch } from 'react-icons/md'
import Chip from 'components/Chip/Chip'
import Link from 'components/Link/Link'

const Filter = ({ filters, filterValues, showFilterDrawer, onSubmit }) => {
  const message = useMessage()
  const [filterInputs, setFilterInputs] = useState(getInitFilterInputs(filters))
  const [filterSelect, setFilterSelect] = useState(
    getInitFilterSelect(filters, message),
  )
  const [open, setOpen] = useState(false)
  const filterInputRef = useRef()

  useEffect(() => {
    if (filterInputRef.current) filterInputRef.current.focus()
  }, [filterSelect])

  if (!filters || filters.length === 0) return null

  const renderFilterSelector = () => (
    <Select
      className={{ field: style['filter__select'] }}
      isSearchable={false}
      isClearable={false}
      options={filters.map((item) => ({
        value: item.id,
        label: message({ id: item.label }),
      }))}
      value={filterSelect}
      onChange={(value) => {
        setFilterSelect(value)
      }}
    />
  )

  const renderFilterInput = () => {
    const filterId = filterSelect.value
    const filter = filters.find((item) => item.id === filterId)
    return filter.render({
      ref: filterInputRef,
      filterInput: filterInputs[filter.id],
      setFilterInput: (val) =>
        setFilterInputs({ ...filterInputs, [filter.id]: val }),
    })
  }

  const renderFilterButton = () => (
    <Button
      className={{ button: style['filter__button'] }}
      icon={<MdSearch size="24px" />}
      type="submit"
    />
  )

  const renderFilterDrawerButton = () => (
    <Button
      className={{ button: style['filter__button'] }}
      icon={<MdMore size="24px" />}
      onClick={() => setOpen(true)}
    />
  )

  const renderFilterValues = () => {
    const filterVals = filterValues.filter((item) => !!item.value)
    if (filterVals.length === 0) return null

    return (
      <div className={style['filter__value']}>
        {filterVals
          .filter((item) => !!item.value)
          .map((item, index) => (
            <Chip
              className={{ button: style['filter__value__button'] }}
              key={index}
              text={getFilterLabel(filters, item)}
              onDelete={() => {
                const filterValues = [...filterVals]
                filterValues.splice(index, 1)
                onSubmit({ filterValues })
              }}
            />
          ))}
        <Link
          className={{ link: style['filter__value__clear'] }}
          label="table.filter.clear"
          onClick={() => onSubmit({ filterValues: [] })}
        />
      </div>
    )
  }

  return (
    <>
      <form
        className={style.filter}
        onSubmit={async (event) => {
          event.preventDefault()
          const ok = await handleSubmit({
            filterValues,
            filterInputs,
            filterSelect,
            onSubmit,
          })
          if (!ok) return
          setFilterInputs(getInitFilterInputs(filters))
        }}
      >
        {renderFilterSelector()}
        <div className={style['filter__input']}>{renderFilterInput()}</div>
        <div className={style['filter__button-panel']}>
          {renderFilterButton()}
          {showFilterDrawer && renderFilterDrawerButton()}
        </div>
      </form>
      {renderFilterValues()}
      <FilterDrawer
        open={open}
        data={{ filters, filterInputs, filterValues }}
        action={{
          setFilterInputs,
          handleClose: () => setOpen(false),
          handleSubmit: async (filterVals) => {
            await onSubmit({ filterValues: filterVals })
            setFilterInputs(getInitFilterInputs(filters))
            setOpen(false)
          },
        }}
      />
    </>
  )
}

export default Filter
