const discount = {
  'module.discount': '折扣',
  'discount.title.list': '折扣目錄',
  'discount.title.add': '新增折扣',
  'discount.title.edit': '修改折扣',
  'discount.title.view': '折扣詳情',
  'discount.section.rule': '折扣規則',
  'discount.section.code': '折扣碼',
  'discount.section.gift': '贈品',
  'discount.field.name': '活動名稱',
  'discount.field.startDate': '開始日期',
  'discount.field.endDate': '結束日期',
  'discount.field.allowCombination': '可疊加使用',
  'discount.field.code': '折扣碼',
  'discount.field.productId': '指定商品',
  'discount.field.quantity': '數量',
  'discount.field.availableQuantity': '可用數量',
  'discount.field.lockedQuantity': '鎖定數量',
  'discount.field.discountScope': '折扣目標',
  'discount.field.discountType': '折扣類型',
  'discount.field.discountValue': '折扣數值',
  'discount.field.minAmount': '最少購買金額',
  'discount.field.minQuantity': '最少購買數量',
  'discount.field.maxQuantity': '每人最多購買數量',
  'discount.scope.ORDER': '訂單',
  'discount.scope.PRODUCT': '商品',
  'discount.type.AMOUNT': '折抵金額',
  'discount.type.PERCENTAGE': '折抵百分比',
  'discount.type.GIFT': '贈品',
  'discount.status.ACTIVE': '正常',
  'discount.status.INACTIVE': '停用',
}

export default discount
