import * as type from 'constants/actionType'

export const initialState = {
  sidebarOpen: getSidebarOpen(),
  token: JSON.parse(localStorage.getItem('token')) || {
    accessToken: '',
    refreshToken: '',
  },
  user: JSON.parse(localStorage.getItem('user')) || {},
  categories: JSON.parse(localStorage.getItem('categories') || '[]'),
  categoryHash: localStorage.getItem('categoryHash') || '',
  products: JSON.parse(localStorage.getItem('products') || '[]'),
  productHash: localStorage.getItem('productHash') || '',
  locations: JSON.parse(localStorage.getItem('locations') || '[]'),
  locationHash: localStorage.getItem('locationHash') || '',
  staffs: JSON.parse(localStorage.getItem('staffs') || '[]'),
  staffHash: localStorage.getItem('staffHash') || '',
  app: JSON.parse(localStorage.getItem('app')) || {},
}

export function reducer(state, action) {
  switch (action.type) {
    case type.SIDEBAR_TOGGLE:
      localStorage.setItem('sidebarOpen', !state.sidebarOpen)
      return { ...state, sidebarOpen: !state.sidebarOpen }
    case type.TOKEN_SET:
      localStorage.setItem('token', JSON.stringify(action.token))
      return { ...state, token: action.token }
    case type.TOKEN_REMOVE:
      const token = { accessToken: '', refreshToken: '' }
      localStorage.setItem('token', JSON.stringify(token))
      return { ...state, token }
    case type.USER_SET:
      localStorage.setItem('user', JSON.stringify(action.user))
      return { ...state, user: action.user }
    case type.CATEGORY_SHELF_SET:
      localStorage.setItem('categoryHash', action.hash || '')
      localStorage.setItem('categories', JSON.stringify(action.data) || [])
      return { ...state, categoryHash: action.hash, categories: action.data }
    case type.PRODUCT_SET:
      localStorage.setItem('productHash', action.hash || '')
      localStorage.setItem('products', JSON.stringify(action.data) || [])
      return { ...state, productHash: action.hash, products: action.data }
    case type.LOCATION_SET:
      localStorage.setItem('locationHash', action.hash || '')
      localStorage.setItem('locations', JSON.stringify(action.data) || [])
      return { ...state, locationHash: action.hash, locations: action.data }
    case type.STAFF_SET:
      localStorage.setItem('staffHash', action.hash || '')
      localStorage.setItem('staffs', JSON.stringify(action.data) || [])
      return { ...state, staffHash: action.hash, staffs: action.data }
    case type.APP_SET:
      localStorage.setItem('app', JSON.stringify(action.data) || {})
      return { ...state, app: action.data }
    default:
      return state
  }
}

function getSidebarOpen() {
  const open = localStorage.getItem('sidebarOpen')
  return open !== null ? open === 'true' : window.innerWidth > 576
}
