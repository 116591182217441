const page = {
  'module.page': '內容頁面',
  'page.title.list': '內容頁面',
  'page.title.add': '新增內容',
  'page.title.edit': '修改內容',
  'page.title.view': '內容詳情',
  'page.field.topic': '分類',
  'page.field.summary': '摘要',
  'page.field.image': '圖示',
  'page.field.content': '內文',
}

export default page
