import { useEffect, useState } from 'react'
import useGlobalState from 'hooks/useGlobalState'
import style from './ActionBar.module.css'
import { Link } from 'components'
import { MdArrowBack } from 'react-icons/md'

const ActionBar = ({ show, backUrl, children }) => {
  const { local } = useGlobalState()
  const [width, setWidth] = useState(window.innerWidth)
  const { sidebarOpen } = local.state
  const marginLeft = sidebarOpen && width >= 576 ? 250 : 0

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
    return () => {
      document.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  }, [])

  if (!show) return null
  return (
    <div className={style.bar} style={{ marginLeft }}>
      <div className={style['bar__content']}>
        <div>
          <Link
            show={!!backUrl}
            icon={<MdArrowBack />}
            label="btn.back"
            href={backUrl}
          />
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}

export default ActionBar
