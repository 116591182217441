const adjust = {
  'module.adjust': '調整單',
  'adjust.toLocationId.required': '倉別為必填欄位',
  'adjust.title.list': '調整單',
  'adjust.title.add': '新增調整單',
  'adjust.title.edit': '修改調整單',
  'adjust.title.view': '調整單詳情',
  'adjust.field.quantity': '調整數量',
}

export default adjust
