const topic = {
  'module.topic': '內容分類',
  'topic.title.list': '內容分類',
  'topic.title.add': '新增內容分類',
  'topic.title.edit': '修改內容分類',
  'topic.title.view': '內容分類詳情',
  'topic.field.title': '標題',
}

export default topic
