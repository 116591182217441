import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import { Button, Message } from 'components'
import { MdClose } from 'react-icons/md'
import style from './Drawer.module.css'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

const Drawer = ({
  width = 360,
  profile,
  title,
  value,
  open,
  onClose = () => {},
  onLoad,
  onSubmit,
  showSubmit = true,
  disableSubmit = false,
  children,
  ...props
}) => {
  const { session, local } = useGlobalState()
  const navigate = useNavigate()
  const pageState = { session, local, profile, navigate }

  useEffect(() => {
    if (open && onLoad) onLoad(pageState, value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, value])

  const Tag = onSubmit ? 'form' : 'div'
  const handleSubmit = (event) => {
    if (disableSubmit) return false

    event.preventDefault()
    if (!onSubmit) return false
    return onSubmit(pageState)
  }

  const drawerWidth = window.innerWidth < 576 ? '100%' : width

  const { loading } = session.state
  return (
    <>
      <div
        className={style['drawer__overlay']}
        style={{
          transform: open ? 'scale(1)' : 'scale(0)',
          opacity: open ? 0.6 : 0,
        }}
      />
      <div
        className={style['drawer']}
        style={{ width: open ? drawerWidth : 0 }}
        {...props}
      >
        <Tag
          style={{ width: drawerWidth }}
          onSubmit={useCallbackOnce(handleSubmit)}
        >
          <div className={style['drawer__header']}>
            <Message className={style['drawer__title']} id={title} />
            <Button
              disabled={loading}
              icon={<MdClose size="24px" />}
              onClick={onClose}
            />
          </div>
          <div className={style['drawer__content']}>{children}</div>
          <div className={style['drawer__footer']}>
            <Button disabled={loading} label="btn.close" onClick={onClose} />
            <Button
              rightIcon={loading ? <LoadingSpinner /> : null}
              show={showSubmit}
              disabled={loading || disableSubmit}
              type="submit"
              label="btn.confirm"
            />
          </div>
        </Tag>
      </div>
    </>
  )
}

export default Drawer
