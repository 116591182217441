import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Message } from 'components'
import style from './Button.module.css'

const Button = ({
  className = {},
  show = true,
  disabled,
  label,
  icon,
  leftIcon,
  rightIcon,
  children,
  onClick = () => {},
  ...props
}) => {
  const navigate = useNavigate()
  if (!show) return null
  if (!leftIcon) leftIcon = icon

  return (
    <button
      disabled={disabled}
      type="button"
      className={cx(style.button, className.button)}
      onClick={() => {
        onClick({ navigate })
      }}
      {...props}
    >
      {leftIcon && (
        <div className={cx(style['button__icon'], className.icon)}>
          {leftIcon}
        </div>
      )}
      {label ? <Message id={label} /> : children}
      {rightIcon && (
        <div className={cx(style['button__icon'], className.icon)}>
          {rightIcon}
        </div>
      )}
    </button>
  )
}

export default Button
