import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import style from './Collapsible.module.css'

const Collapsible = ({ className, open, children }) => {
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    setHeight(open ? ref.current.scrollHeight : 0)
  }, [open])

  return (
    <div
      className={cx(style.collapsible, className)}
      ref={ref}
      style={{ maxHeight: `${height}px` }}
    >
      {children}
    </div>
  )
}

export default Collapsible
