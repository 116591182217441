import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import style from './Content.module.css'

const Content = ({ open, local, session, navigate, onToggle }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const isSmallScreen = width < 576
  let marginLeft = open ? 250 : 0
  let handleClick = () => {}

  if (isSmallScreen) marginLeft = 0
  if (isSmallScreen && open) handleClick = onToggle

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
    return () => {
      document.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  }, [])

  return (
    <div className={style.content} style={{ marginLeft }} onClick={handleClick}>
      <Navbar
        local={local}
        session={session}
        navigate={navigate}
        onToggle={onToggle}
      />
      <main className={style.main}>
        <Outlet />
      </main>
    </div>
  )
}

export default Content
