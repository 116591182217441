import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Layout, ConfirmModal } from 'containers'
import { LoadingLogo, LoadingBar, Notification } from 'components'
import Login from 'views/Login/Login'

const StaffList = lazy(() => import('views/StaffList/StaffList'))
const WarehouseList = lazy(() => import('views/WarehouseList/WarehouseList'))
// const CategoryList = lazy(() => import('views/CategoryList/CategoryList'))
const CategoryShelfList = lazy(() =>
  import('views/CategoryShelfList/CategoryShelfList'),
)
const CategoryProductList = lazy(() =>
  import('views/CategoryProductList/CategoryProductList'),
)
const ProductList = lazy(() => import('views/ProductList/ProductList'))
const ProductPage = lazy(() => import('views/ProductPage/ProductPage'))
const ShelfList = lazy(() => import('views/ShelfList/ShelfList'))
const ShelfPage = lazy(() => import('views/ShelfPage/ShelfPage'))
const BuyList = lazy(() => import('views/BuyList/BuyList'))
const BuyPage = lazy(() => import('views/BuyPage/BuyPage'))
const AdjustList = lazy(() => import('views/AdjustList/AdjustList'))
const AdjustPage = lazy(() => import('views/AdjustPage/AdjustPage'))
const ProcessList = lazy(() => import('views/ProcessList/ProcessList'))
const ProcessPage = lazy(() => import('views/ProcessPage/ProcessPage'))
const RefundList = lazy(() => import('views/RefundList/RefundList'))
const RefundPage = lazy(() => import('views/RefundPage/RefundPage'))
const InventoryList = lazy(() => import('views/InventoryList/InventoryList'))
const AppPage = lazy(() => import('views/AppPage/AppPage'))
const PromoList = lazy(() => import('views/PromoList/PromoList'))
const PopularList = lazy(() => import('views/PopularList/PopularList'))
const ContentList = lazy(() => import('views/ContentList/ContentList'))
const ContentPage = lazy(() => import('views/ContentPage/ContentPage'))
const LinkList = lazy(() => import('views/LinkList/LinkList'))
const TopicList = lazy(() => import('views/TopicList/TopicList'))
const OrderList = lazy(() => import('views/OrderList/OrderList'))
const OrderPage = lazy(() => import('views/OrderPage/OrderPage'))
const ReportOrderPage = lazy(() =>
  import('views/ReportOrderPage/ReportOrderPage'),
)
const DiscountList = lazy(() => import('views/DiscountList/DiscountList'))
const DiscountPage = lazy(() => import('views/DiscountPage/DiscountPage'))

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingLogo />}>
        <Routes>
          <Route index path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route path="staff" element={<StaffList />} />
            <Route path="warehouse" element={<WarehouseList />} />
            {/* <Route path="category" element={<CategoryList />} />
            <Route path="category/:id" element={<CategoryList />} /> */}
            <Route path="category/shelf" element={<CategoryShelfList />} />
            <Route
              path="category/shelf/:id/product"
              element={<CategoryProductList />}
            />
            <Route path="product" element={<ProductList />} />
            <Route path="product/:profile" element={<ProductPage />} />
            <Route path="product/:id/:profile" element={<ProductPage />} />
            <Route path="shelf" element={<ShelfList />} />
            <Route path="shelf/:profile" element={<ShelfPage />} />
            <Route path="shelf/:id/:profile" element={<ShelfPage />} />
            <Route path="buy" element={<BuyList />} />
            <Route path="buy/:profile" element={<BuyPage />} />
            <Route path="buy/:id/:profile" element={<BuyPage />} />
            <Route path="adjust" element={<AdjustList />} />
            <Route path="adjust/:profile" element={<AdjustPage />} />
            <Route path="adjust/:id/:profile" element={<AdjustPage />} />
            <Route path="refund" element={<RefundList />} />
            <Route path="refund/:profile" element={<RefundPage />} />
            <Route path="refund/:id/:profile" element={<RefundPage />} />
            <Route path="order" element={<OrderList />} />
            <Route path="order/:profile" element={<OrderPage />} />
            <Route path="order/:id/:profile" element={<OrderPage />} />
            <Route path="process" element={<ProcessList />} />
            <Route path="process/:profile" element={<ProcessPage />} />
            <Route path="process/:id/:profile" element={<ProcessPage />} />
            <Route path="inventory" element={<InventoryList />} />
            <Route path="app/edit" element={<AppPage />} />
            <Route path="promo" element={<PromoList />} />
            <Route path="popular" element={<PopularList />} />
            <Route path="page" element={<ContentList />} />
            <Route path="page/:profile" element={<ContentPage />} />
            <Route path="page/:id/:profile" element={<ContentPage />} />
            <Route path="link" element={<LinkList />} />
            <Route path="topic" element={<TopicList />} />
            <Route path="discount" element={<DiscountList />} />
            <Route path="discount/:profile" element={<DiscountPage />} />
            <Route path="discount/:id/:profile" element={<DiscountPage />} />
            <Route path="report/order" element={<ReportOrderPage />} />
          </Route>
        </Routes>
      </Suspense>
      <LoadingBar />
      <Notification />
      <ConfirmModal />
    </BrowserRouter>
  )
}

export default App
