const inventory = {
  'inventory.title.list': '存量目錄',
  'inventory.field.expireDayLeft': '剩餘效期(天)',
  'inventory.field.availableQuantity': '可用庫存',
  'inventory.field.availableBox': '可用庫存(箱)',
  'inventory.field.lockedQuantity': '已鎖庫存',
  'inventory.field.pendingQuantity': '在途庫存',
}

export default inventory
