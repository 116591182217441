const process = {
  'module.process': '加工單',
  'process.title.list': '加工單',
  'process.title.add': '新增加工單',
  'process.title.edit': '修改加工單',
  'process.title.addItem': '新增加工商品',
  'process.title.editItem': '修改加工商品',
  'process.title.view': '加工單詳情',
  'process.section.beforeProduct': '加工前商品',
  'process.section.afterProduct': '加工後商品',
}

export default process
