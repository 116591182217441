import { forwardRef, useImperativeHandle, useEffect } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import useGlobalState from 'hooks/useGlobalState'
import useMessage from 'hooks/useMessage'
import { PAGE_SET } from 'constants/actionType'
import { Button, LoadingSpinner, Message, StatusChip } from 'components'
import { ActionBar } from 'containers'
import style from './Page.module.css'

const Page = (
  {
    className = {},
    pageId,
    backUrl,
    title,
    status,
    statusPrefix,
    profile = 'view',
    children,
    onLoad,
    onSubmit,
  },
  ref,
) => {
  const { session, local } = useGlobalState()
  const message = useMessage()
  const navigate = useNavigate()
  const pageState = { session, local, profile, navigate }
  const readonly = profile === 'view'

  useEffect(() => {
    document.title = message({ id: title })

    if (pageId) session.dispatch({ type: PAGE_SET, id: pageId })
    if (onLoad) onLoad(pageState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, profile])

  useImperativeHandle(ref, () => ({
    handleLoad: () => {
      if (onLoad) onLoad(pageState)
    },
  }))

  const Tag = onSubmit ? 'form' : 'div'
  const handleSubmit = (event) => {
    event.preventDefault()
    if (!onSubmit) return false
    onSubmit(pageState)
  }

  const renderStatus = () => {
    if (!status) return null
    return <StatusChip show={readonly} status={status} prefix={statusPrefix} />
  }

  const { loading } = session.state
  return (
    <Tag className={style.page} onSubmit={useCallbackOnce(handleSubmit)}>
      <div className={style['page__title-container']}>
        <Message as="h1" className={style['page__title']} id={title} />
        {renderStatus()}
      </div>
      <div className={cx(style['page__content'], className.content)}>
        {children}
      </div>
      <ActionBar show={!readonly} backUrl={backUrl}>
        <Button
          rightIcon={loading ? <LoadingSpinner /> : null}
          disabled={loading}
          label="btn.save"
          type="submit"
        />
      </ActionBar>
    </Tag>
  )
}

export default forwardRef(Page)
