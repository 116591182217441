import { Message } from 'components'
import style from './Definition.module.css'

const Definition = ({
  show = true,
  direction = 'column',
  label,
  value,
  children,
  labelProps,
  valueProps,
  ...props
}) => {
  if (!show) return null
  if (value && typeof value === 'object') return null

  const isVert = direction === 'column'
  const isEmpty = value === undefined || value === null || value === ''

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction,
        alignItems: isVert ? 'flex-start' : 'center',
      }}
      {...props}
    >
      <Message
        className={style['definition__label']}
        style={{ marginBottom: isVert ? 'var(--space-1)' : 0 }}
        id={label}
      />
      {children || (
        <div
          className={style['definition__value']}
          style={{
            marginTop: isVert ? 'var(--space-1)' : 0,
            marginLeft: isVert ? 0 : 'var(--space-3)',
          }}
        >
          {isEmpty ? '\u2014' : value}
        </div>
      )}
    </div>
  )
}

export default Definition
