const popular = {
  'module.popular': '熱銷商品',
  'popular.title.list': '熱銷商品',
  'popular.title.add': '新增熱銷商品',
  'popular.title.edit': '修改熱銷商品',
  'popular.title.load': '導入商品',
  'popular.message.count': '導入近30天最多銷售數量商品',
  'popular.field.count': '導入數量',
}

export default popular
