const warehouse = {
  'module.warehouse': '倉別',
  'warehouse.title.list': '倉別目錄',
  'warehouse.title.add': '新增倉別',
  'warehouse.title.edit': '修改倉別',
  'warehouse.field.name': '名稱',
  'warehouse.field.isStock': '可用庫存',
}

export default warehouse
