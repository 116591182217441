import { Message } from 'components'
import style from './StatusChip.module.css'
import { getStatusColor } from './statusChipAction'

const StatusChip = ({ show = true, status, prefix = 'status', ...props }) => {
  if (!show) return null

  const color = getStatusColor(status)
  return (
    <div className={style.chip} {...props}>
      <div
        className={style['chip__indicator']}
        style={{ backgroundColor: color }}
      />
      <Message id={`${prefix}.${status}`} />
    </div>
  )
}

export default StatusChip
