import staff from './staff'
import category from './category'
import product from './product'
import shelf from './shelf'
import inventory from './inventory'
import buy from './buy'
import adjust from './adjust'
import refund from './refund'
import process from './process'
import app from './app'
import promo from './promo'
import popular from './popular'
import warehouse from './warehouse'
import page from './page'
import link from './link'
import topic from './topic'
import order from './order'
import discount from './discount'
import report from './report'

const messages = {
  'error.internal': '系統錯誤！',
  'error.required': '此欄位為必填欄',
  'error.requiredField': '{name}為必填欄',
  'error.min': '最小值需為{val}',
  'error.minLength': '最少字符需為{val}',
  'error.maxLength': '最多字符為{val}',
  'error.passNotMatch': '輸入密碼不一致',
  'error.invalidAddress': '街道、電梯為必填欄位',
  'token.expired': '請重新登錄！',
  'token.invalid': '帳戶與密碼不符！',
  'staff.invalidCredential': '帳戶與密碼不符！',
  'btn.submit': '提交',
  'btn.confirm': '確認',
  'btn.approve': '核准',
  'btn.complete': '完成',
  'btn.reject': '駁回',
  'btn.save': '儲存',
  'btn.back': '返回',
  'btn.cancel': '取消',
  'btn.preview': '預覽',
  'btn.close': '關閉',
  'btn.add': '新增',
  'btn.edit': '修改',
  'btn.delete': '刪除',
  'btn.more': '更多',
  'btn.import': '匯入',
  'btn.export': '匯出',
  'btn.download': '下載',
  'btn.print': '列印',
  'btn.search': '搜尋',
  'btn.openSync': '開啟同步',
  'btn.closeSync': '關閉同步',
  'btn.sync': '重新同步',
  'btn.copy': '複製',
  'btn.locked': '已鎖定',
  'btn.unlocked': '未鎖定',
  'btn.sort': '排序',
  'message.save.success': '儲存成功！',
  'message.delete.success': '刪除成功！',
  'message.delete': '確認刪除{name}?',
  'message.approve': '確認核准{name}?',
  'message.complete': '確認完成{name}?',
  'message.empty': '[無資料]',
  'message.yes': '是',
  'message.no': '否',
  'placeholder.textfield': '請輸入{label}',
  'title.delete': '刪除{name}',
  'title.approve': '核准{name}',
  'title.complete': '完成{name}',
  'title.addImage': '新增圖片',
  'title.addEmbed': '新增影片',
  'app.name': '愛生活管理系統',
  'app.login': '登錄',
  'app.logout': '登出',
  'app.signup': '註冊',
  'status.ACTIVE': '已完成',
  'status.INACTIVE': '已刪除',
  'status.PENDING': '待處理',
  'status.ON': '開啟',
  'status.OFF': '關閉',
  'sidebar.product': '商品管理',
  'sidebar.stock': '庫存管理',
  'sidebar.refund': '退貨管理',
  'sidebar.website': '電商管理',
  'sidebar.report': '報表匯出',
  'sidebar.setting': '廠商設定',
  'table.message.empty': '無資料',
  'table.pagination.totalPage': '{page}/{totalPage} 頁',
  'table.pagination.totalCount': '共 {totalCount} 筆',
  'table.filter.title': '搜尋條件',
  'table.filter.clear': '全部清除',
  'login.title': '登錄管理平台',
  'login.field.username': '帳號',
  'login.field.password': '密碼',
  'login.btn.login': '登錄',
  'login.success': '成功登錄',
  'logout.success': '成功登出',
  'field.seq': '序號',
  'field.name': '名稱',
  'field.ticketId': '單號',
  'field.transDate': '日期',
  'field.fromDate': '開始日期',
  'field.toDate': '結束日期',
  'field.createdAt': '建立時間',
  'field.status': '狀態',
  'field.memo': '備註',
  'field.type': '類別',
  'field.product': '商品',
  'field.productName': '商品名稱',
  'field.categoryName': '商品分類',
  'field.warehouseName': '倉別',
  'field.balance': '庫存',
  'field.quantity': '數量',
  'field.expireDate': '效期',
  'field.batchNo': '批號',
  'field.image': '圖示',
  ...staff,
  ...category,
  ...product,
  ...shelf,
  ...inventory,
  ...buy,
  ...adjust,
  ...refund,
  ...app,
  ...promo,
  ...popular,
  ...warehouse,
  ...process,
  ...page,
  ...link,
  ...topic,
  ...order,
  ...discount,
  ...report,
}

export default messages
