const link = {
  'module.link': '導航連結',
  'link.section.header': '頂部連結',
  'link.section.sidebar': '側欄連結',
  'link.title.list': '導航連結',
  'link.title.add': '新增導航連結',
  'link.title.edit': '修改導航連結',
  'link.title.view': '導航連結詳情',
  'link.field.type': '類型',
  'link.field.label': '連結文字',
  'link.field.categoryId': '商品分類',
  'link.field.topicId': '內容分類',
  'link.field.itemType': '類型',
  'link.field.itemValue': '連結',
  'link.field.pageId': '內容頁面',
  'link.type.CATEGORY': '商品分類',
  'link.type.PRODUCT': '商品目錄',
  'link.type.POPULAR_PRODUCT': '熱銷商品',
  'link.type.TOPIC': '內容分類',
  'link.type.PAGE': '內容頁面',
  'link.type.DROPDOWN': '下拉選單',
}

export default link
