import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import Sidebar from './Sidebar'
import Content from './Content'
import { SIDEBAR_TOGGLE } from 'constants/actionType'
import { handlers } from './layoutAction'

const Layout = () => {
  const { local, session } = useGlobalState()
  const navigate = useNavigate()
  const { token, sidebarOpen } = local.state
  const { pageId } = session.state
  const { handleLoad } = handlers({ local })
  const handleSidebarToggle = () => {
    local.dispatch({ type: SIDEBAR_TOGGLE })
  }

  useEffect(() => {
    if (!token.accessToken) {
      navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.accessToken])

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Sidebar
        open={sidebarOpen}
        onToggle={handleSidebarToggle}
        pageId={pageId}
      />
      <Content
        open={sidebarOpen}
        local={local}
        session={session}
        navigate={navigate}
        onToggle={handleSidebarToggle}
      />
    </>
  )
}

export default Layout
